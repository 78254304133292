export class PathConstants {
  public static getUserDetailsPath(uid: string) {
    return `super-admins/${uid}`;
  }

  public static getAttorneysPath() {
    return `/attorney-orgs`;
  }

  public static getProvidersPath() {
    return `/registered-service-providers`;
  }

  public static getProviderOrgPath(orgCode: string) {
    return `/provider-org-${orgCode}/0rg-metadata`;
  }

  public static getRegisteredServiceProviderPath() {
    return `/registered-service-providers`;
  }

  public static addGoldStdOrderTypes(orgCode: string) {
    return `/provider-org-${orgCode}/order-types`;
  }

  public static addGoldStdAppointmentTypes(orgCode: string) {
    return `/provider-org-${orgCode}/appointment-types`;
  }

  public static addGoldStdHXForms(orgCode: string) {
    return `/provider-org-${orgCode}/history-forms`;
  }

  public static addGoldStdPEForms(orgCode: string) {
    return `/provider-org-${orgCode}/physical-exams`;
  }

  public static addGoldStdOfficeForms(orgCode: string) {
    return `/provider-org-${orgCode}/doc-templates`;
  }

  public static addGoldStdReports(orgCode: string) {
    return `/provider-org-${orgCode}/reports`;
  }

  public static getDoctorPath() {
    return `/pmd-users`;
  }

  public static getDoctorUserPath(uid: string) {
    return `/pmd-users/${uid}`;
  }

  public static getHistorianOrgs() {
    return `/historian-orgs`;
  }

  public static getProcedureOrgs() {
    return `/procedures-orgs`;
  }

  public static getBillerOrgs() {
    return `/biller-orgs`;
  }

  public static getCaseVueUsersPath() {
    return `/case-vue-orgs`;
  }

  public static getHistorianOrgPath(orgCode: string) {
    return `/historian-orgs/historian-org-${orgCode}`;
  }

  public static getProcedureOrgPath(orgCode: string) {
    return `/procedures-orgs/procedures-org-${orgCode}`;
  }

  public static getDiagnosticOrgPath(orgCode: string) {
    return `/diagnostics-orgs/diagnostics-org-${orgCode}`;
  }

  public static getBillerOrgPath(orgCode: string) {
    return `/biller-orgs/biller-org-${orgCode}`;
  }

  public static getAttorneyOrgPath(orgCode: string) {
    return `/attorney-orgs/attorney-org-${orgCode}`;
  }

  public static getRootOrg(orgCode: string) {
    return `/provider-org-${orgCode}`;
  }

  public static getCaseWorkflowPath(orgCode: string) {
    return `/provider-org-${orgCode}/case-workflows/`;
  }

  public static getProviderUsers() {
    return `/provider-users`;
  }

  public static getProviderUserPath(uid: string) {
    return `/provider-users/${uid}`;
  }

  public static getHelpDeskPath(app: string) {
    return `/help-desk-cms/${app}`;
  }

  public static get0rgMetadata(orgCode: string) {
    return `/provider-org-${orgCode}/0rg-metadata`
  }
}
